import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import { CSSTransition } from "react-transition-group";
// import MaximoForm from "../components/MaximoForm";
// import MaximoServiceFacilities from "../components/MaximoServiceFacilities";
// import MaximoCertification from "../components/MaximoCertification";
// import MaximoSolutionList from "../components/MaximoSolutionList";
// import Logo from "../components/Logo";
// import Testimonials from "../components/Testimonials";
import MaximoScale from "../components/MaximoScale";
import SedinIbmLogo from "../images/maximo/sedin-ibm.svg";
import CallIcon from "../images/maximo/call-icon.svg";
import PointOneIcon from "../images/maximo/point-one-ibm.svg";
import PointTwoIcon from "../images/maximo/point-two-ibm.svg";
import PointThreeIcon from "../images/maximo/point-three-ibm.svg";
import MaximoCaseStudyBgImageOne from "../images/maximo/maximo-Case-studies-01.png";
import MaximoCaseStudyBgImageTwo from "../images/maximo/maximo-Case-studies-02.png";
import MaximoCaseStudyBgImageThree from "../images/maximo/maximo-Case-studies-03.png";
import IBMStarLogo from "../images/maximo/ibm-star-logo.svg";
import IBMCapterra from "../images/maximo/ibm-capterra.svg";
import "../styles/main.scss";
import MaximoLeadForm from "../components/MaximoLeadForm";
import IBMMaximoTestimonials from "../components/IBM-maximo-testimonials";

const MaximoCampaign = ({ data }) => {
    const [showModal, setShowModal] = useState(false);

    const handleModel = () => {
        setShowModal(true);
    };
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="text-p3  text-clr-white text-fw-medium gap-1x">{children}</p>
            ),
            [BLOCKS.UL_LIST]: (node, children) => {
                return <ul>{children}</ul>;
            },
            [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
            [BLOCKS.LIST_ITEM]: (node, children) => (
                <li className="text-p3  text-clr-secondary text-fw-regular">{children}</li>
            ),
        },
    };
    const bgImage =
        data.contentfulMaximoCampaignLandingpage.maximoHerobanner?.gatsbyImageData?.images.fallback
            .src;
    const ibmMaximoLeaders = data.contentfulMaximoCampaignLandingpage.ibmMaximoMembers;

    const maximoCaseStudies = [
        {
            id: 1,
            title: "Public utilities",
            description:
                "The City of Henderson implemented IBM Maximo with Sedin to improve public utility asset management, featuring real-time updates and integration with ESRI ArcMap, resulting in enhanced productivity and inventory accuracy.",
            backgroundImage: MaximoCaseStudyBgImageOne,
        },
        {
            id: 2,
            title: "Energy",
            description:
                "Sedin successfully implemented IBM Maximo for a countrywide hydroelectric plant, to digitize asset and work management, maintenance planning, calibration, and integration with ERP and DMS systems. The project helped establish a robust maintenance framework for the new facility",
            backgroundImage: MaximoCaseStudyBgImageTwo,
        },
        {
            id: 1,
            title: "Pharmaceutical",
            description:
                "We are helping one of the largest pharmaceutical manufacturers in the world shift to IBM MAS. This implementation will be an on-premise MAS upgrade to help make full use of latest updates to MAS for their Engineering operations.",
            backgroundImage: MaximoCaseStudyBgImageThree,
        },
    ];
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            {/* maximo campaign page hero-section  */}
            <section
                className="maximo-campaign-hero"
                id="maximo-campaign-hero"
                style={{
                    background: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                }}
            >
                <div className="maximo-campaign-hero container">
                    <div className="logo-header">
                        <div className="site-logo">
                            <div to="/" className="logo-inner">
                                <img src={SedinIbmLogo} alt="sedin-logo" />
                            </div>
                        </div>
                        {/* <div className="partner-logo">
                            <GatsbyImage
                                image={
                                    data.contentfulMaximoCampaignLandingpage.logo.gatsbyImageData
                                }
                                alt={data.contentfulMaximoCampaignLandingpage.logo.title}
                            />
                        </div> */}
                        <div className="flex flex-ai-c maximo-call">
                            <img src={CallIcon} alt="call-icon" className="call-icon-logo" />
                            <a className="text-p2 text-clr-white" href="tel: +61 411 333 390">
                                Call Us: +61 411 333 390
                            </a>
                        </div>
                    </div>
                    <div className="maximo-campaign-hero__wrapper">
                        <div className="maximo-campaign-hero__grid">
                            <div>
                                <div className="maximo-campaign-hero__content">
                                    <div className="maximo-campaign-hero__text">
                                        <h1 className="text-h1 gap-1x text-fw-strong text-clr-white">
                                            {/* Maximo consulting services */}
                                            {data.contentfulMaximoCampaignLandingpage.title}
                                        </h1>

                                        <p>
                                            {" "}
                                            {renderRichText(
                                                data.contentfulMaximoCampaignLandingpage
                                                    .description,
                                                options
                                            )}
                                        </p>
                                        <div className="gap-2x">
                                            <button
                                                className="sdn-cta-btn text-fw-bold"
                                                onClick={handleModel}
                                            >
                                                Request a free MAS 9 demo
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M13.2673 4.20926C12.9674 3.92357 12.4926 3.93511 12.2069 4.23504C11.9213 4.53497 11.9328 5.0097 12.2327 5.29539L18.4841 11.25H3.75C3.33579 11.25 3 11.5858 3 12C3 12.4142 3.33579 12.75 3.75 12.75H18.4842L12.2327 18.7047C11.9328 18.9904 11.9213 19.4651 12.2069 19.7651C12.4926 20.065 12.9674 20.0765 13.2673 19.7908L20.6862 12.7241C20.8551 12.5632 20.9551 12.358 20.9861 12.1446C20.9952 12.0978 21 12.0495 21 12C21 11.9504 20.9952 11.902 20.986 11.8551C20.955 11.6419 20.855 11.4368 20.6862 11.276L13.2673 4.20926Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="maximo-campaign-hero__bottom">
                                    <div className="flex flex-ai-c">
                                        <img src={PointOneIcon} alt="hero-section-points" />
                                        <p className="text-p4 text-clr-white text-fw-normal">
                                            Work order intelligence, powerful failure prediction and
                                            more
                                        </p>
                                    </div>
                                    <div className="flex flex-ai-c">
                                        <img src={PointTwoIcon} alt="hero-section-points" />
                                        <p className="text-p4 text-clr-white text-fw-normal">
                                            Extensive IoT integrations, real-time monitoring and
                                            analytics
                                        </p>
                                    </div>
                                    <div className="flex flex-ai-c">
                                        <img src={PointThreeIcon} alt="hero-section-points" />
                                        <p className="text-p4 text-clr-white text-fw-normal">
                                            Centralized, integrated control of processes.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* <MaximoForm
                                formTitle={data?.contentfulMaximoCampaignLandingpage?.formHeading}
                            /> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* client logo section  */}
            <section className="maximo-campaign-clients">
                <div className="container container--mid-v2">
                    <p className="text-p3 text-clr-primary text-fw-medium gap-1x">
                        Trusted by industry leaders across the globe
                    </p>
                    <div className="maximo-campaign-clients__grid">
                        <div className="maximo-campaign-clients__grid-logo">
                            {data.contentfulMaximoCampaignLandingpage.clients.map((el, i) => (
                                <div key={i}>
                                    {el.gatsbyImageData ? (
                                        <GatsbyImage
                                            class="facility-icon"
                                            image={el.gatsbyImageData}
                                            alt={el.title}
                                        />
                                    ) : (
                                        <img
                                            className="facility-icon"
                                            src={el?.file?.url || ""}
                                            alt={el.title}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* scale with our maximo CoE section  */}
            <section className="maximo-campaign-solution">
                <div className="container container--mid-v2">
                    <div className="maximo-campaign-solution_text">
                        <p className="text-p2 text-clr-secondary text-fw-medium gap-05x">
                            Services
                        </p>
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-4x">
                            {/* Scale with our Maximo CoE for your asset management */}
                            {data.contentfulMaximoCampaignLandingpage.maximoServiceSolutionTitle}
                        </h1>
                    </div>

                    <MaximoScale
                        data={data.contentfulMaximoCampaignLandingpage.maximoServiceSolutionList}
                    />
                </div>
            </section>
            {/* casestudies section  */}
            <section className="ibm-maximo-campaign-casestudies">
                <div className="container container--mid-v2">
                    <div className="maximo-campaign-solution_text">
                        <p className="text-p2 text-clr-secondary text-fw-medium gap-05x">
                            Case studies
                        </p>
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-4x">
                            Driving Success with Innovative IBM Maximo Solutions
                        </h1>
                    </div>
                    <div className="ibm-maximo-campaign-casestudies__grid gap-3x">
                        {maximoCaseStudies?.map((casestudy, id) => (
                            <div key={id} className="ibm-maximo-campaign-casestudies__grid-item">
                                <div
                                    className="ibm-maximo-campaign-casestudies__grid-item__cards"
                                    style={{
                                        background: `url(${casestudy.backgroundImage})`,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center center",
                                    }}
                                >
                                    <p className="text-p1 text-clr-white text-fw-medium">
                                        {casestudy.title}
                                    </p>
                                    <p className="text-p5 text-clr-white text-fw-normal">
                                        {casestudy.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="maximo-cta-btn-box">
                        <button className="sdn-cta-btn" onClick={handleModel}>
                            Schedule a demo{" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M13.2673 4.20926C12.9674 3.92357 12.4926 3.93511 12.2069 4.23504C11.9213 4.53497 11.9328 5.0097 12.2327 5.29539L18.4841 11.25H3.75C3.33579 11.25 3 11.5858 3 12C3 12.4142 3.33579 12.75 3.75 12.75H18.4842L12.2327 18.7047C11.9328 18.9904 11.9213 19.4651 12.2069 19.7651C12.4926 20.065 12.9674 20.0765 13.2673 19.7908L20.6862 12.7241C20.8551 12.5632 20.9551 12.358 20.9861 12.1446C20.9952 12.0978 21 12.0495 21 12C21 11.9504 20.9952 11.902 20.986 11.8551C20.955 11.6419 20.855 11.4368 20.6862 11.276L13.2673 4.20926Z"
                                    fill="white"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </section>

            {/* testimonial section */}
            <section className="maximo-testimonials">
                <div className="container container--mid-v2">
                    <div className="maximo-campaign-solution_text">
                        <p className="text-p2 text-clr-secondary text-fw-medium gap-05x">
                            Testimonials
                        </p>
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-4x">
                            Industry-best solutions for experts and leaders
                        </h1>
                    </div>
                    <div className="maximo-testimonials-grid">
                        <IBMMaximoTestimonials
                            testimonialData={
                                data.contentfulMaximoCampaignLandingpage.maximoTestimonial
                            }
                        />
                    </div>
                </div>
            </section>
            {/* statistics section  */}
            <section className="maximo-statistics">
                <div className="container container--mid-v2">
                    <div className="maximo-statistics__grid">
                        {/* <div className="maximo-statistics__grid-text">
                            <h2 className="text-h2 text-fw-medium text-clr-primary gap-2x">
                                Partner with experts
                            </h2>
                        </div> */}
                        <div className="maximo-statistics__grid-content">
                            <div className="maximo-statistics__grid-content-item">
                                <div className="maximo-statistics__grid-content-item__value">
                                    15+
                                </div>
                                <div className="maximo-statistics__grid-content-item__name">
                                    Years in Experience
                                </div>
                            </div>
                            {/* <div className="maximo-statistics__grid-content-item">
                                <div className="maximo-statistics__grid-content-item__value">
                                    50+
                                </div>
                                <div className="maximo-statistics__grid-content-item__name">
                                    Projects Delivered
                                </div>
                            </div> */}
                            <div className="maximo-statistics__grid-content-item">
                                <div className="maximo-statistics__grid-content-item__value">
                                    50+
                                </div>
                                <div className="maximo-statistics__grid-content-item__name">
                                    Certified Maximo Consultants
                                </div>
                            </div>
                            <div className="maximo-statistics__grid-content-item">
                                <div className="maximo-statistics__grid-content-item__value">
                                    7+
                                </div>
                                <div className="maximo-statistics__grid-content-item__name">
                                    Years of IBM Partnership
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* our team section  */}
            <section className="maximo-our-team">
                <div className="container container--mid-v2">
                    <div className="maximo-campaign-solution_text">
                        <p className="text-p2 text-clr-secondary text-fw-medium gap-05x">
                            Our Team
                        </p>
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-4x">
                            Say hello to our team members
                        </h1>
                    </div>
                    <div className="maximo-our-team__grid">
                        {ibmMaximoLeaders?.map((member, id) => (
                            <div key={id} className="maximo-our-team__grid-item">
                                <GatsbyImage
                                    image={member.colorLogo?.gatsbyImageData}
                                    alt={member.colorLogo?.title}
                                    className="leader-image"
                                    loading="lazy"
                                />
                                <p className="leader-name text-p1 text-clr-primary text-fw-medium">
                                    {member.name}
                                </p>
                                <p className=" text-p4 text-clr-secondary text-fw-medium gap-1x">
                                    {member.designation}
                                </p>
                                <a
                                    className="text-decor-none linked"
                                    href={member.linkedinUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <p className="load-more-btn text-p4 text-fw-medium">Linkedin</p>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/* ctabanner section  */}
            <section className="maximo-cta-banner">
                <div className="container container--mid-v2">
                    <div className="cta-banner">
                        <div className="cta-banner__grid-item">
                            <div className="cta-banner__grid-item__text">
                                <div className="text-h2 cta-text text-fw-medium">
                                    {
                                        data.contentfulMaximoCampaignLandingpage.footerMaximoCta
                                            .header
                                    }
                                    {/* Ready to launch your next project? */}
                                </div>
                                <p className="text-p3 text-clr-secondary">
                                    With lots of unique blocks, you can easily build a page without
                                    coding. Build your next landing page.
                                </p>
                            </div>
                            <div>
                                <button className=" sdn-cta-btn" onClick={handleModel}>
                                    {
                                        data.contentfulMaximoCampaignLandingpage.footerMaximoCta
                                            .ctaText
                                    }
                                    {/* Request a free MAS 9 demo */}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M13.2673 4.20926C12.9674 3.92357 12.4926 3.93511 12.2069 4.23504C11.9213 4.53497 11.9328 5.0097 12.2327 5.29539L18.4841 11.25H3.75C3.33579 11.25 3 11.5858 3 12C3 12.4142 3.33579 12.75 3.75 12.75H18.4842L12.2327 18.7047C11.9328 18.9904 11.9213 19.4651 12.2069 19.7651C12.4926 20.065 12.9674 20.0765 13.2673 19.7908L20.6862 12.7241C20.8551 12.5632 20.9551 12.358 20.9861 12.1446C20.9952 12.0978 21 12.0495 21 12C21 11.9504 20.9952 11.902 20.986 11.8551C20.955 11.6419 20.855 11.4368 20.6862 11.276L13.2673 4.20926Z"
                                            fill="white"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="ibm-maximo-rating">
                            <img src={IBMStarLogo} alt="star-logo" className="ibm-star-logo" />
                            <p className="text-p3 text-clr-white gap-05x">4.8 / 5 rating</p>
                            <img src={IBMCapterra} alt="capterra-logo" className="ibm-star-logo" />
                        </div>
                    </div>
                </div>
                <div className="container ibm-footer">
                    <div className="footer__bottom-text text-clr-white">
                        © {new Date().getFullYear()} &nbsp; Sedin Technologies Inc
                    </div>

                    <div className="footer__bottom-links">
                        <Link to="/terms-and-conditions/">Terms & Conditions</Link>
                        <Link to="/privacy-policy/">Privacy Policy</Link>
                    </div>
                </div>
            </section>
            <CSSTransition in={showModal} timeout={300} classNames="fade" unmountOnExit>
                <MaximoLeadForm showModal={showModal} setShowModal={setShowModal} footer />
            </CSSTransition>
        </>
    );
};

export const query = graphql`
    query MaximoCampaign($id: String) {
        contentfulMaximoCampaignLandingpage(id: { eq: $id }) {
            title
            description {
                raw
            }
            logo {
                gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                title
            }
            clients {
                gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                title
                file {
                    url
                }
            }
            formHeading {
                raw
            }
            maximoServiceSolutionTitle
            maximoServiceSolutionList {
                id
                title
                name
                description {
                    description
                }
                solutionLists
                slug
                ogImage {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                    title
                    file {
                        url
                    }
                }
                buttonText
                serviceIcon {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
                    file {
                        url
                    }
                }
            }
            maximoIntroHeader
            maximoIntroTitle
            maximoIntroDescription {
                raw
            }
            maximoIntroImage {
                gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                title
            }
            maximoServiceFacilities {
                icon {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                    title
                    file {
                        url
                    }
                }
                title
                description
            }
            maximoCertifiedTitle
            maximoCertification {
                gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                title
            }
            maximoTestimonial {
                id
                personName
                personDesignation
                profileImage {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                    title
                }
                testimonialType
                testimonialStatement {
                    testimonialStatement
                }
            }
            maximoSolutionTitle
            maximoSolutionFacilities {
                icon {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                    file {
                        url
                    }
                    title
                }
                title
                description
            }
            footerMaximoCta {
                ctaText
                ctaSlug
                header
            }
            maximoHerobanner {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
            }
            ibmMaximoMembers {
                id
                name
                colorLogo {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
                }
                designation
                linkedinUrl
            }
        }
    }
`;
export default MaximoCampaign;
